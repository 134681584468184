
import UserAvatar from '@/components/image/UserAvatar.vue'
import JigTextarea from '@/components/input/JigTextarea.vue'
import JigTextField from '@/components/input/JigTextField.vue'
import JigTooltip from '@/components/input/JigTooltip.vue'
import SearchField from '@/components/input/SearchField.vue'
import DataTable from '@/components/material/DataTable.vue'
import ManageUserModal from '@/components/modals/ManageUserModal.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import { ClosePromptFunction, ShowPromptFunction } from '@/components/modals/type'
import GenericError from '@/components/snackbars/GenericError.vue'
import IconAddUser from '@/components/svg/IconAddUser.vue'
import IconInfo from '@/components/svg/IconInfoFilled.vue'
import IconTrash from '@/components/svg/IconTrash.vue'
import { JigConst, SubscriptionConst, TenantConst, UtilsConst } from '@/constants'
import FiltersMixin from '@/mixin/Filters'
import SubscriptionTracking from '@/mixin/SubscriptionTracking'
import UserList from '@/mixin/UserList'
import { DataTableHeadings } from '@/modules/library/types'
import { Permissions } from '@/security/permissions'
import { PermissionActions } from '@/store/constants'
import {
  JigListPaginatedResponse,
  Tenant,
  TenantSubscription,
  TenantUser,
  TenantUserRemoveRequest,
  UserRoleDefinition,
} from '@/store/modules/app/types'
import { Namespace, StandardObject } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { DataTableConfig } from '@/utils/data-table-helper'
import { HSHelpers } from '@/utils/hs-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking, segmentIdentityTracking } from '@/utils/tracking'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation, State } from 'vuex-class'

@Component({
  components: {
    'manage-user-modal': ManageUserModal,
    'data-table': DataTable,
    'generic-error': GenericError,
    'jig-textarea': JigTextarea,
    'jig-text-field': JigTextField,
    'jig-tooltip': JigTooltip,
    'prompt-yes-no': PromptYesNo,
    'search-field': SearchField,
    'user-avatar': UserAvatar,
    'icon-add-user': IconAddUser,
    'icon-info': IconInfo,
    'icon-trash': IconTrash,
  },
})
export default class TenantUsersVue extends Mixins(FiltersMixin, SubscriptionTracking, UserList) {
  @State('initializing', { namespace: Namespace.Utils })
  public initializing!: boolean
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('currentSignedInUser', { namespace: Namespace.App })
  public currentSignedInUser!: TenantUser | null
  @State('tenantUserRoles', { namespace: Namespace.App })
  public tenantUserRoles!: UserRoleDefinition[]
  @State('tenantUsersItemsPerPage', { namespace: Namespace.Utils })
  public tenantUsersItemsPerPage!: number
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean

  @Getter('isPreparingApp', { namespace: Namespace.Utils })
  public isPreparingApp!: boolean
  @Getter('tenantUserRolesMap', { namespace: Namespace.App })
  public tenantUserRolesMap!: StandardObject

  @Mutation('updateMyTenantUsers', { namespace: Namespace.App })
  public updateMyTenantUsers: any
  @Mutation('updateTenantUsersItemsPerPage', { namespace: Namespace.Utils })
  public updateTenantUsersItemsPerPage: any

  @Action('loadTenantUserRoles', { namespace: Namespace.App })
  public loadTenantUserRoles: any
  @Action('loadTenantUsers', { namespace: Namespace.App })
  public loadTenantUsers: any
  @Action('removeTenantUser', { namespace: Namespace.App })
  public removeTenantUser: any
  @Action('loadUserOwnedJigs', { namespace: Namespace.Jig })
  public loadUserOwnedJigs: any

  // When dynamically assign ref attributes using v-for,
  // Vue may automatically group multiple instances with the same ref into an array.
  // This is because Vue expects that multiple components might share the same ref key in a v-for loop,
  // so it creates an array to hold all the component instances.
  // Hence we assign dynamic prompt as PromptYesNo[0].
  // Accessing their methods will be using $refs.xxPromptYesNo[0].SomeMethod()
  public $refs!: Vue['$refs'] & {
    genericError: GenericError
    removeUsersPromptYesNo: PromptYesNo[]
    teamAdminRequiredPromptYesNo: PromptYesNo[]
    transferJigsRequiredPromptYesNo: PromptYesNo[]
    userLimitPromptYesNo: PromptYesNo[]
    upgradeSucceedPromptYesNo: PromptYesNo[]
    warningPromptYesNo: PromptYesNo[]
    manageEditUserModal: ManageUserModal
    [key: string]: Vue | Element | Vue[] | Element[] | undefined
  }

  private editedUser: TenantUser = TenantConst.defaultTenantUser
  private dialogEditUserMode: boolean = false
  private rolesListingModes: any
  private tenantUserManager: boolean = false
  private tenantUserAdmin: boolean = false
  private canAddBillableUserRoles: boolean = false
  private openHSChatFailed: boolean = false
  private subscription: TenantSubscription = SubscriptionConst.defaultSubscription
  private tableColumns: DataTableHeadings = {}
  private tenantUserHeaders: DataTableHeadings = DataTableConfig.tenantUsers.headers

  private searchCriteria: string = ''
  private sourceTenantUsers: TenantUser[] = []
  private rowsSelected: any[] = []
  private rowsSelectedUsers: TenantUser[] = []
  private usersRequireJigTransfer: TenantUser[] = []
  private warningPromptMessageHtml: string = ''
  private removeUserResultMsg: string = ''
  private isRemovingUsers: boolean = false

  private lastTmNotes = 'You cannot remove or downgrade the last team manager in your team'

  constructor() {
    super()
    this.resetEditedUser()
  }

  protected async created() {
    this.rolesListingModes = this.rolesListingMode

    if (this.isSuperUser) {
      this.tableColumns = this.tenantUserHeaders
      this.tableColumns.Uid = {
        copy: 'UID',
        sort: false,
      }
    } else {
      for (const key in this.tenantUserHeaders) {
        this.tableColumns[key] = this.tenantUserHeaders[key]
      }
    }
  }

  protected async mounted() {
    if (this.$route.query.checkoutresult === 'success') {
      await this.SubscriptionEventTracking({
        gtmEventData: {
          action: 'CheckoutSuccess',
          category: 'CheckoutSelect',
          label: 'CheckoutSuccess',
        },
        segmentEventName: 'CheckoutSuccess',
      })

      this.$callRefMethod<ShowPromptFunction>('upgradeSucceedPromptYesNo', 'ShowPrompt')
    }
    this.loadData()
  }

  @Watch('myTenantIndex')
  private onMyTenantIndexPropertyChanged(value: number, oldValue: number) {
    this.loadData()
  }

  @Watch('tenantUserCount')
  private async onTenantUserCountUpdated(value: number) {
    if (value !== TenantHelpers.InvalidTenantUserCount) {
      await segmentIdentityTracking(this.myTenants, this.myTenant, { TenantUserCount: value })
    }
  }

  private get searchEvents(): any {
    return UtilsConst.searchEvents
  }

  private get dynamicPrompYesNo(): any {
    return {
      upgradeSucceed: {
        ref: 'upgradeSucceedPromptYesNo',
        props: {
          'no-link-href': this.openJigSpace,
          'no-link-cta': true,
          'no-btn-msg': 'Open JigSpace',
          'yes-btn-msg': 'Back to Dashboard',
          'no-btn-color': 'primary',
          'no-link-target': '_blank',
          'is-yes-btn-outlined': true,
          'has-divider': false,
          'is-cta-center-aligned': true,
        },
        events: {},
        slots: {
          title: {
            class: 'modal__title--badge',
            html: `<div class="modal__badge"><img src="img/tada@2x.png" alt="Tada" class="modal__emoji" /></div>You've successfully subscribed to the starter plan`,
          },
          content: {
            html: `<div class="text-center">Your account now has access to: 300MB upload per 3D file, 5GB of storage, Advanced webAR viewer, 4 user accounts,<br />email and live chat support</div>`,
          },
        },
      },
      userLimit: {
        ref: 'userLimitPromptYesNo',
        props: {
          'yes-btn-msg': 'Upgrade plan',
        },
        events: {
          'on-answer-yes': this.upgradePlan,
        },
        slots: {
          title: {
            class: 'modal__title--flex',
            icon: {
              is: 'icon-info',
              class: 'modal__icon icon-info modal__icon--error',
            },
            html: `User limit reached`,
          },
          content: {
            html: `Your current plan has a ${this.userLimitCount} user limit. Modify the number of users in your account or upgrade your plan to add more users.`,
          },
        },
      },
      removeUsers: {
        ref: 'removeUsersPromptYesNo',
        props: {
          'yes-btn-msg': 'Delete',
          'yes-btn-color': 'danger',
          isProcessing: this.isRemovingUsers,
          noClickEvent: this.closeRemoveUserPrompt,
        },
        events: {
          'on-answer-yes': this.removeUsers,
        },
        slots: {
          title: {
            class: 'modal__title--flex',
            icon: {
              is: 'icon-info',
              class: 'modal__icon icon-info modal__icon--error',
            },
            html: 'Remove team member',
          },
          content: {
            html: this.removeUsersModalContent,
          },
        },
      },
      transferRequired: {
        ref: 'transferJigsRequiredPromptYesNo',
        props: {
          'yes-btn-msg': 'Manage content',
        },
        events: {
          'on-answer-yes': this.manageJigs,
          'on-answer-no': this.resetTransferData,
        },
        slots: {
          title: {
            class: 'modal__title--flex',
            icon: {
              is: 'icon-info',
              class: 'modal__icon icon-info',
            },
            html: 'Transfer Jigs before removing',
          },
          content: {
            html: `<p>You're about to remove ${this.usersOwnJigsListContent.preCopy}${this.usersOwnJigsListContent.emaiListHtml}Their content needs to be transferred to a new user or deleted to complete removal.</p>`,
          },
        },
      },
      warning: {
        ref: 'warningPromptYesNo',
        props: {
          'require-ctas': false,
        },
        events: {},
        slots: {
          title: {
            class: 'modal__title--flex',
            icon: {
              is: 'icon-info',
              class: 'modal__icon icon-info',
            },
            html: 'Remove team member',
          },
          content: {
            html: this.warningPromptMessageHtml,
          },
        },
      },
      teamAdminRequired: {
        ref: 'teamAdminRequiredPromptYesNo',
        props: {
          'require-ctas': false,
        },
        events: {},
        slots: {
          title: {
            class: 'modal__title--flex',
            icon: {
              is: 'icon-info',
              class: 'modal__icon icon-info',
            },
            html: 'Team Admin required',
          },
          content: {
            html: `<p>At least one Team Admin is required for your team. Please assign yourself as Team Admin before making any changes to Team Manager members.</p>`,
          },
        },
      },
    }
  }

  private get isTeamAdmin(): boolean {
    return TenantHelpers.IsTeamAdmin(this.currentSignedInUser)
  }

  private get isTeamManager(): boolean {
    return TenantHelpers.IsTeamManager(this.currentSignedInUser, this.myTenant.ID)
  }

  // Both Team Manager and Team Admin are Admin User
  private get onlyOneAdminUserRemain(): boolean {
    if (this.tenantUsers.length === 0) {
      return false
    }

    const teamManagers: TenantUser[] = this.tenantUsers.filter(
      (user: TenantUser) => TenantHelpers.IsTeamManager(user, this.myTenant.ID) || TenantHelpers.IsTeamAdmin(user)
    )

    return teamManagers.length === 1
  }

  private get teamAdminCount(): number {
    if (this.tenantUsers.length === 0) {
      return 0
    }

    const teamAdmins: TenantUser[] = this.tenantUsers.filter((user: TenantUser) => TenantHelpers.IsTeamAdmin(user))

    return teamAdmins.length
  }

  // Only a TeamAdmin/SuperUser can modify TeamAdmin permission.
  // We also make sure user can upgrade to TeamAdmin if there is none TeamAdmin exists in the tenant.
  private get isTeamAdminOrSuperUser(): boolean {
    return (
      this.isTeamAdmin ||
      this.isSuperUser ||
      (this.dialogEditUserMode && this.editedUser.Uid === this.$auth0.uid && this.isTeamManager && this.teamAdminCount === 0)
    )
  }

  private get additionalSeatsQty(): number {
    return this.subscription.AdditionalSeats || 0
  }

  private get userLimitCount(): any {
    const seatsLimit =
      this.subscription.SeatsIncludedInTier === SubscriptionConst.Subscription.defaultSeatsIncludedInTier
        ? '-'
        : (this.subscription.SeatsIncludedInTier as number) + this.additionalSeatsQty

    return this.subscription ? seatsLimit : ''
  }

  private get openJigSpace(): string {
    return AppHelper.openJigSpaceUrl()
  }

  private get removeUsersModalContent(): string {
    return this.removeUserResultMsg !== ''
      ? this.removeUserResultMsg
      : `<p>You're about to remove ${this.deleteUsersListContent.preCopy}${this.deleteUsersListContent.emaiListHtml}This action cannot be undone. Do you want to continue?</p>`
  }

  private get deleteUsersListContent(): StandardObject {
    let preCopy = ''
    let htmlString = ''
    if (this.rowsSelectedUsers.length > 1) {
      preCopy = `following ${this.rowsSelectedUsers.length} users`
      htmlString = `</p><ol class="list--ol">`

      this.rowsSelectedUsers.forEach((user: TenantUser) => {
        const userInfo = this.getUserInfo(user)
        htmlString += `<li>${userInfo.displayName}${userInfo.isNameEqEmail ? '' : ` (${userInfo.email})`}</li>`
      })

      htmlString += '</ol><p>'
    } else {
      const userInfo = this.getUserInfo(this.editedUser)
      preCopy = `${userInfo.displayName}${userInfo.isNameEqEmail ? '' : ` (${userInfo.email})`}`
      htmlString = '<br/>'
    }

    return {
      preCopy: `${preCopy}.`,
      emaiListHtml: htmlString,
    }
  }

  private get usersOwnJigsListContent(): StandardObject {
    let preCopy = ''
    let htmlString = ''
    if (this.usersRequireJigTransfer.length > 1) {
      preCopy = `following ${this.usersRequireJigTransfer.length} users who own content`
      htmlString = `</p><ol class="list--ol">`

      this.usersRequireJigTransfer.forEach((user: TenantUser) => {
        const userInfo = this.getUserInfo(user)
        htmlString += `<li>${userInfo.displayName}${userInfo.isNameEqEmail ? '' : ` (${userInfo.email})`}</li>`
      })

      htmlString += '</ol><p>'
    } else if (this.usersRequireJigTransfer.length === 1) {
      const userInfo = this.getUserInfo(this.usersRequireJigTransfer[0])
      preCopy = `${userInfo.displayName}${userInfo.isNameEqEmail ? '' : ` (${userInfo.email})`} who own content`
      htmlString = ' '
    }

    return {
      preCopy: `${preCopy}.`,
      emaiListHtml: htmlString,
    }
  }

  private async loadData() {
    if (this.myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const loadResult = await this.getMyTenantUsersData()
      if (loadResult && this.tenantUserRoles.length === 0) {
        await this.loadTenantUserRoles()
      } else if (!loadResult) {
        // If we get an error, it can be misleading if the tenantUsers are being shown from a previously selected tenant so we reset them here.
        this.updateMyTenantUsers([])
      }
    }
    this.setPermissionsFromTenantInToken()
    if (this.$refs.manageEditUserModal) {
      this.$refs.manageEditUserModal.UpdateUserTeamRole()
    }
    this.openHSChatFailed = false
    this.subscription = this.myTenant.Subscription
  }

  private async getMyTenantUsersData(payload: StandardObject = {}) {
    const result = await this.loadTenantUsers(payload)
    this.sourceTenantUsers = this.tenantUsers
    this.rowsSelected = []
    this.rowsSelectedUsers = []

    return result
  }

  private getExtraPermissoinNamesCsvString(tenantUser: TenantUser) {
    if (!tenantUser.roles.includes(TenantHelpers.roleTeamAdmin1)) {
      return tenantUser.extraPermissionNames.join(', ')
    }

    return tenantUser.extraPermissionNames.filter((p: string) => p !== TenantHelpers.ExtraPermissionManager).join(', ')
  }

  private getRoleDisplayNamesCsvString(roleMode: number, tenantUser: TenantUser): string {
    return this.getRoleDefsForUserAndAction(roleMode, tenantUser)
      .map((role: UserRoleDefinition) => role.DisplayName)
      .join(', ')
  }

  private getRoleDefsForUserAndAction(roleMode: number, tenantUser?: TenantUser): UserRoleDefinition[] {
    return this.GetRoleDefsForUserAndAction(roleMode, tenantUser)
  }

  private upgradePlan() {
    segmentEventTracking('TenantUserLimitTeamPanel_UpgradePanelClicked', {
      tenantName: this.myTenant.Name,
      tenantId: this.editedUser.tenantId,
    })
    this.$router.push({
      path: '/subscription',
      query: {
        stripeSuccess: this.$route.path,
      },
    })
  }

  private getUserInfo(user: TenantUser) {
    const name = user.name || user.username

    return {
      displayName: name,
      email: user.email,
      isNameEqEmail: name === user.email,
    }
  }

  private openAddUserDialog() {
    this.$refs.manageEditUserModal.OpenAddUserDialog()
  }

  private openUpdateUserDialog(user: TenantUser) {
    this.editedUser = user
    this.$refs.manageEditUserModal.OpenUpdateUserDialog(user)
  }

  private onDialogEditModeChange(isEditMode: boolean) {
    this.dialogEditUserMode = isEditMode
  }

  private onUserLimitReached() {
    this.$callRefMethod<ShowPromptFunction>('userLimitPromptYesNo', 'ShowPrompt')
  }

  private onInviteFailed(payload: any) {
    this.warningPromptMessageHtml = payload.warningPromptMessageHtml
    this.$callRefMethod<ShowPromptFunction>('warningPromptYesNo', 'ShowPrompt')
  }

  private async onUserInvited(payload: { getUserDataPayload: any; callback: () => void }) {
    await this.getMyTenantUsersData(payload.getUserDataPayload)

    if (typeof payload.callback === 'function') {
      payload.callback()
    }
  }

  private onUserUpdateFailed() {
    this.$callRefMethod<ShowPromptFunction>('teamAdminRequiredPromptYesNo', 'ShowPrompt')
  }

  private onUserUpdated() {
    this.getMyTenantUsersData()
  }

  private openHSChat() {
    this.openHSChatFailed = !HSHelpers.OpenHSChat()
  }

  // Check if users to delete still own Jigs
  private async isJigTransferRequired() {
    const allPromises: Promise<any>[] = []
    const usersToRemove: TenantUser[] = this.rowsSelectedUsers.length > 1 ? this.rowsSelectedUsers : [this.editedUser]

    usersToRemove.forEach((user: TenantUser, index: number) => {
      const payload = {
        checkOtherUser: true,
        filter: [
          `${JigConst.JigListQueryFilters.ownerUserId},eq,${user.Uid}`,
          `${JigConst.JigListQueryFilters.sharedWithUser},eq,${false}`,
          `${JigConst.JigListQueryFilters.tenantOwner},eq,${this.myTenant.ID}`,
        ],
        requestUserId: user.Uid,
        sharedWithUser: false,
        mutationName: '',
        actionDetails: `user (UID: ${user.Uid}) owned jigs`,
      }
      allPromises.push(this.loadUserOwnedJigs(payload))
    })

    // Promise.all response is an array of fulfillment values,
    // in the order of the promises passed, regardless of completion order.
    const checkResults = await Promise.all(allPromises)

    checkResults.forEach((jigsListResponse: JigListPaginatedResponse) => {
      if (jigsListResponse.total_rows > 0) {
        this.usersRequireJigTransfer.push(usersToRemove.find((u: TenantUser) => u.Uid === jigsListResponse.uid) as TenantUser)
      }
    })
  }

  private async openRemoveUserPrompt(user?: TenantUser) {
    if (user == null && this.rowsSelected.length === 0) {
      throw new Error('Selected user is null or no user is selected.')
    }

    if (user) {
      this.editedUser = user
    } else if (user == null && this.rowsSelected.length === 1) {
      this.editedUser = this.rowsSelected[0]
    }

    await this.isJigTransferRequired()

    if (this.usersRequireJigTransfer.length > 0) {
      this.$callRefMethod<ShowPromptFunction>('transferJigsRequiredPromptYesNo', 'ShowPrompt')
    } else {
      this.$callRefMethod<ShowPromptFunction>('removeUsersPromptYesNo', 'ShowPrompt')
    }
  }

  private closeRemoveUserPrompt() {
    this.$callRefMethod<ClosePromptFunction>('removeUsersPromptYesNo', 'ClosePrompt')
    this.removeUserResultMsg = ''
    this.rowsSelected = []
    this.rowsSelectedUsers = []
  }

  private async removeUsers() {
    this.isRemovingUsers = true
    let successfullyRemovedUser = 0
    const usersToRemove: TenantUser[] = this.rowsSelectedUsers.length > 1 ? this.rowsSelectedUsers : [this.editedUser]
    const allPromises: Promise<unknown>[] = []

    usersToRemove.forEach((user: TenantUser) => {
      const payload = {
        Uid: user.Uid,
        TenantId: user.tenantId,
      } as TenantUserRemoveRequest

      allPromises.push(this.removeTenantUser(payload))
    })

    const removeResults = await Promise.all(allPromises)
    const erroredUsers: TenantUser[] = []

    removeResults.forEach((result: any, index) => {
      if (result.isSuccessful) {
        successfullyRemovedUser += 1
        segmentEventTracking('DeleteUser', {
          jigUserId: usersToRemove[index].Uid,
          user: usersToRemove[index].email,
          tenantId: usersToRemove[index].tenantId,
        })
      } else {
        if (!result.isSuccessful && result.error) {
          const uid = parseInt(result.error.config.url.substring(21), 10)

          if (!Number.isNaN(uid)) {
            erroredUsers.push(usersToRemove.find((u: TenantUser) => u.Uid === uid) as TenantUser)
          }
        } else if (!result.isSuccessful) {
          this.removeUserResultMsg = `Something's wrong when removing users, please try again later.`
        }
      }
    })

    if (erroredUsers.length > 0) {
      this.removeUserResultMsg = `<p>Failed to remove following team members:</p><ol class="list--ol">`

      erroredUsers.forEach((user: TenantUser) => {
        const userInfo = this.getUserInfo(user)
        this.removeUserResultMsg += `<li>${userInfo.displayName}${userInfo.isNameEqEmail ? '' : ` (${userInfo.email})`}</li>`
      })

      this.removeUserResultMsg += `</ol>`

      if (erroredUsers.length < usersToRemove.length) {
        this.removeUserResultMsg += '<p>Other team members have been removed successfully.</p>'
      }
    }

    if (successfullyRemovedUser > 0) {
      this.getMyTenantUsersData()
    }

    this.isRemovingUsers = false

    if (removeResults.length !== successfullyRemovedUser || this.apiError !== '') {
      return
    }
  }

  private manageJigs() {
    this.$router.push({ path: '/team-jigs' })
  }

  private resetTransferData() {
    this.usersRequireJigTransfer = []
  }

  private setPermissionsFromTenantInToken() {
    if (this.isSuperUser) {
      this.tenantUserManager = true
      this.tenantUserAdmin = true
      this.canAddBillableUserRoles = true
      return
    }
    const tenantTokens = this.$auth0.tenants

    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)
    if (tenant == null) {
      this.tenantUserManager = false
      this.tenantUserAdmin = false
      this.canAddBillableUserRoles = false
      return false
    }

    const token = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, tenant.ID)
    this.tenantUserManager = token !== undefined && TenantHelpers.IsTeamManager(this.currentSignedInUser, this.myTenant.ID)
    this.tenantUserAdmin = token !== undefined && TenantHelpers.IsTeamAdmin(this.currentSignedInUser)

    // Billing sources such as apple and android are not able have price or products changed off the device.
    this.canAddBillableUserRoles = token !== undefined && TenantHelpers.CanAddBillableUsers(token)
  }

  // Only a Team Admin or SuperUser can delete Team Admins or Team Managers directly
  private userIsTeamAdminOrSuperuser(user: TenantUser): boolean {
    return TenantHelpers.IsTeamAdmin(user) || user.roles.indexOf(TenantHelpers.roleSuperUser1) > UtilsConst.invalidIndex
  }

  // Only a Team Manager or SuperUser can delete Team Managers or Team Managers directly
  private userIsTeamManager(user: TenantUser): boolean {
    return TenantHelpers.IsTeamManager(user, this.myTenant.ID)
  }

  private canDeleteUser(user: TenantUser): boolean {
    // Can't delete self
    if (this.$auth0.uid === user.Uid) {
      return false
    }

    // Only user with tenant manage permission can delete users
    // Team Manager can delete Creator/Presentor
    // Only TeamAdmin or SuperUser can delete TeamAdmin/Superuser
    return (
      (this.tenantUserManager && !this.userIsTeamAdminOrSuperuser(user)) || (this.tenantUserAdmin && this.userIsTeamAdminOrSuperuser(user))
    )
  }

  private itemsPerPageChange(payload: any) {
    this.updateTenantUsersItemsPerPage(payload.newItemsPerPage)
  }

  private selectAllRowsChanged(payload: any) {
    this.rowsSelected = payload.rowsSelected
    this.rowsSelectedUsers = payload.rowsSelectedItems
  }

  private onUserSelectChange(payload: any) {
    this.rowsSelected = payload.rowsSelected
    this.rowsSelectedUsers = payload.rowsSelectedItems
  }

  private resetEditedUser() {
    this.editedUser = { ...TenantConst.defaultTenantUser }
  }

  private resetUserList() {
    this.sourceTenantUsers = this.tenantUsers
  }

  private searchTenantUsers(searchCriteria: string) {
    this.searchCriteria = searchCriteria

    this.sourceTenantUsers = this.tenantUsers.filter((user: TenantUser) => {
      let isAMatch = false
      if (this.searchCriteria) {
        const userName = user.name.toLowerCase()
        const userEmail = user.email.toLowerCase()
        const keyword = this.searchCriteria.toLowerCase()

        if (userName.includes(keyword) || userEmail.includes(keyword)) {
          isAMatch = true
        }

        const matchingByRole: string | undefined = user.roles.find((role: string) => {
          const matchingRoleObject: UserRoleDefinition = this.tenantUserRolesMap[role]

          if (matchingRoleObject != null) {
            return matchingRoleObject.DisplayName.toLowerCase().includes(this.searchCriteria.toLowerCase().trim())
          } else {
            return role.includes(this.searchCriteria.toLowerCase().trim())
          }
        })

        const matchingExtraPerName: string | undefined = user.extraPermissionNames.find((perm: string) =>
          perm.toLowerCase().includes(this.searchCriteria.toLowerCase())
        )

        if ((user.roles.length && matchingByRole != null) || (user.extraPermissionNames.length && matchingExtraPerName != null)) {
          isAMatch = true
        }
      } else {
        isAMatch = true
      }
      return isAMatch
    })
  }
}

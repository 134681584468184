import { TrackingEvents } from '@/modules/library/types'
import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({})
export default class SubscriptionTracking extends Vue {
  @State('myTenant', { namespace: Namespace.App }) public myTenant!: Tenant
  @State('tenantUserCount', { namespace: Namespace.App }) public tenantUserCount!: number

  private eventsToTrack: Map<any, any> = new Map()

  @Watch('tenantUserCount')
  private async onTenantUserCountChanged(value: number) {
    if (value !== TenantHelpers.InvalidTenantUserCount) {
      this.eventsToTrack.forEach(async (value: any, key: string) => {
        await this.SubscriptionEventTracking({
          segmentEventName: key,
          segmentEventExtraData: value,
        })

        this.eventsToTrack.delete(key)
      })
    }
  }

  public async SubscriptionEventTracking(trackingEvents: TrackingEvents) {
    if (trackingEvents.gtmView) {
      this.$gtm.trackView(trackingEvents.gtmView, this.$route.path)
    }

    if (trackingEvents.gtmEventData) {
      this.$gtm.trackEvent(trackingEvents.gtmEventData)
    }

    if (trackingEvents.segmentEventName && this.tenantUserCount !== TenantHelpers.InvalidTenantUserCount) {
      const segmentData = {
        Email: this.$auth0.user.email,
        jigUserId: this.$auth0.uid,
        TenantID: this.myTenant.ID,
        TenantName: this.myTenant.Name,
        TenantUserCount: this.tenantUserCount,
        Subscription: AppHelper.getTierType(this.myTenant.Subscription.TierType),
        ...trackingEvents.segmentEventExtraData,
      }

      await segmentEventTracking(trackingEvents.segmentEventName, segmentData)
    } else if (trackingEvents.segmentEventName && this.tenantUserCount === TenantHelpers.InvalidTenantUserCount) {
      this.eventsToTrack.set(trackingEvents.segmentEventName, trackingEvents.segmentEventExtraData)
    }
  }
}
